<div class="mat-dialog-container" *ngIf="!isLoading">
  <h1 mat-dialog-title>Personal Information</h1>

  <mat-dialog-content>
    <form class="personal-info-form" [formGroup]="personalInformationForm">
      <div class="field-group">
        <div class="form-field form-field-input">
          <mat-label class="form-field-label">First Name:</mat-label>
          <mat-form-field appearance="outline">
            <input matInput
                   type="text"
                   placeholder="First Name"
                   formControlName="firstName"
                   required>
            <mat-error *ngIf="firstNameControl?.errors?.required">Please enter your first name</mat-error>
          </mat-form-field>
        </div>

        <div class="form-field form-field-input">
          <mat-label class="form-field-label">Last Name:</mat-label>
          <mat-form-field appearance="outline">
            <input matInput
                   placeholder="Last Name"
                   formControlName="lastName"
                   required>
            <mat-error *ngIf="lastNameControl?.errors?.required">Please enter your last name</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="field-group">
        <div class="form-field form-field-input">
          <mat-label class="form-field-label">Login Email:</mat-label>
          <mat-form-field appearance="outline">
            <input matInput
                   placeholder="Login Email"
                   formControlName="loginEmail"
            >
          </mat-form-field>
        </div>
      </div>
      <div class="field-group">
        <div class="form-field form-field-input">
          <mat-label class="form-field-label">Secondary Email:</mat-label>
          <mat-form-field appearance="outline">
            <input matInput
                   placeholder="Secondary Email"
                   formControlName="secondaryEmail"
                   required
            >
            <mat-error *ngIf="personalInformationForm.get('secondaryEmail')?.hasError('required')">Please enter an email
              address
            </mat-error>
            <mat-error *ngIf="personalInformationForm.get('secondaryEmail')?.hasError('pattern')">Please enter a valid
              email address
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="field-group">
        <div class="form-field form-field-input">
          <mat-label class="form-field-label">Contact Number:</mat-label>
          <mat-form-field appearance="outline">
            <input matInput
                   placeholder="Contact Number"
                   formControlName="contactNumber"
                   required>
            <mat-error *ngIf="personalInformationForm.get('contactNumber')?.hasError('required')">Please enter a contact
              number
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="field-group">
        <div class="form-field form-field-input">
          <mat-label class="form-field-label">Company Name</mat-label>
          <mat-form-field appearance="outline">
            <input matInput
                   placeholder="Company Name"
                   formControlName="companyName"
                   required>
          </mat-form-field>
        </div>
      </div>
      <div class="field-group">
        <div class="form-field form-field-input">
          <mat-label class="form-field-label">Company ID</mat-label>
          <mat-form-field appearance="outline">
            <input matInput
                   placeholder="Company ID"
                   formControlName="companyId"
                   required>
          </mat-form-field>
        </div>
      </div>
      <div class="field-group">
        <div class="form-field form-field-input">
          <mat-label class="form-field-label">Postal Address:</mat-label>
          <mat-form-field appearance="outline">
            <input matInput
                   placeholder="Postal Address"
                   formControlName="postalAddress"
                   required>
          </mat-form-field>
        </div>
      </div>
      <div class="policies">
        <mat-checkbox class="platform-cbx" formControlName="acceptedPlatformPolicies">Accepted Platform Agreement
        </mat-checkbox>
        <mat-checkbox class="user-cbx" formControlName="acceptedUserPolicies">Accepted User Agreement</mat-checkbox>
      </div>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-button
            class="btn btn-red cancel-btn"
            (click)="closeDialog()">
      Cancel
    </button>
    <button mat-raised-button
            class="btn btn-green"
            (click)="saveAccount()"
            [disabled]="!(formChanged | async) || personalInformationForm.invalid">
      Save My Account
    </button>
  </mat-dialog-actions>
</div>
<mat-spinner *ngIf="isLoading"></mat-spinner>
