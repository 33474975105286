import {ModuleWithProviders} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {MemberDownloadsComponent} from 'src/app/components/member-downloads/member-downloads.component';
import {MembersListComponent} from 'src/app/components/members-list/members-list.component';
import {UploadDropzoneComponent} from '../../components/upload-dropzone/upload-dropzone.component'
import {TotalInvoiceComponent} from "../../components/invoice-totals/total-invoices.component";
import {HomeCoreModule} from "../modules/home-core.module";
import {ProcessorsListComponent} from "../../components/processors-list/processors-list.component";
import {XeroProductsListComponent} from "../../components/xero-products-list/xero-products-list.component";

const homeCoreRoutes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'agreements',
        component: UploadDropzoneComponent
      },
      {
        path: 'members',
        component: MembersListComponent
      },
      {
        path: 'processors',
        component: ProcessorsListComponent
      },
      {
        path: 'products',
        component: XeroProductsListComponent
      },
      {
        path: 'download-data',
        component: MemberDownloadsComponent
      },
      {
        path: 'total-invoices',
        component: TotalInvoiceComponent
      }, {
        path: '',
        pathMatch: 'full',
        redirectTo: 'members'
      },
    ]
  }
];

export const HomeCoreRouting: ModuleWithProviders<HomeCoreModule> = RouterModule.forChild(homeCoreRoutes);
